export class SetCurrentUser {
  static readonly type = '[App] Set current user';

  constructor(public user: any) {}
}

export class Logout {
  static readonly type = '[App] Logout';
}

export class Login {
  static readonly type = '[App] Login';

  constructor(public credentials: any) {}
}

export class ValidateUser {
  static readonly type = '[App] Validate user';
}

export class GetPerPages {
  static readonly type = '[App] Get per pages';
}

export class GetPagination {
  static readonly type = '[App] get pagination';
}

export class ResetAuthErrors {
  static readonly type = '[Auth] Reset form errors';
}

export class UpdateUserStatus {
  static readonly type = '[App] Update user status';

  constructor(public status: any) {}
}

export class UpdateUserName {
  static readonly type = '[App] Update user name';

  constructor(
    public firstName: string,
    public lastName: string,
  ) {}
}

export class GetRules {
  static readonly type = '[App] Get rules';
}

export class GetLoginUseMicrosoftUrl {
  static readonly type = '[App] GetLoginUseMicrosoftUrl';
}

export class LoginUseMicrosoftUrl {
  static readonly type = '[App] LoginUseMicrosoftUrl';

  constructor(public token: string) {}
}

export class UpdateUserSsn {
  static readonly type = '[App] LoginUseMicrosoftUrl';

  constructor(public ssn: string) {}
}

export class GetEmployeeAnnualBonusSetting {
  static readonly type = '[Base] GetEmployeeAnnualBonusSetting';
}

export class UpdateEmployeeAnnualBonusSetting {
  static readonly type = '[Base] UpdateEmployeeAnnualBonusSetting';

  constructor(
    public period: 'one' | 'two' | 'three',
    public value: number | string,
  ) {}
}
